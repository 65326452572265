import React, { useState } from "react";
import "./index.css";
import { CountryDropdown } from "react-country-region-selector";
import Input from "../../components/input";
import { donateNow } from "../../services/userService";
import swal from "@sweetalert/with-react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import Spinner from "../../components/spinner/Spinner";

const DonationForm = () => {
  const [payDetails, setPayDetails] = useState({
    firstName: "",
    lastName: "",
    amount: "",
    phone: "",
    email: "",
    country: "",
    currency: "",
  });

  const { email, firstName, lastName, country, phone, amount, currency } =
    payDetails;

  const config = {
    public_key: "FLWPUBK-d7680be9103ea527a15b6114640bc87b-X",
    tx_ref: Date.now(),
    amount: amount,
    currency: currency,
    payment_options: "card,mobilemoney,ussd",
    // specified redirect URL
    redirect_url: "https://donate.rhapsodybibles.org/?success=true",
    customer: {
      email: email,
      phonenumber: phone,
      name: firstName,
    },
    customizations: {
      title: "Rhapsody Bible",
      description: "Rhapsody Bible Donation",
      logo: "https://rhapsodybible.org/wp-content/uploads/2020/03/Rhapsody-Bible-logo.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);
  const [isLoading, setIsLoading] = useState(false);

  const variousCountry = [
    { title: "DOLLARS", val: "USD" },
    { title: "POUNDS", val: "GBP" },
    { title: "RANDS", val: "ZAR" },
    { title: "NAIRA", val: "NGN" },
  ];

  function handleFormInputChange({ target }) {
    const payInfo = { ...payDetails };
    payInfo[target.name] = target.value;
    setPayDetails(payInfo);
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
       handleFlutterPayment({
         callback: (response) => {
           console.log(response);
           if (response.status === "successful") {
              donateNow(payDetails)
                .then((res) => {
                  setIsLoading(true);
                  console.log(res);
                })
                .catch((err) => {
                  setIsLoading(false);
                  console.log(err);
                });
           } else {
             return swal("Error", "Your payment failed", "error");
           }
           closePaymentModal(); // this will close the modal programmatically
         },
         onClose: (result) => {
           setIsLoading(false);
           setPayDetails({
             firstName: "",
             lastName: "",
             amount: "",
             phone: "",
             email: "",
             country: "",
             currency: "",
           });
           if (result) {
             return swal("Error", "Your payment was cancelled", "error");
           } else
             return swal(
               "Successful",
               "Your donations have been recieved",
               "success"
             );
         },
       });
   
    console.log(payDetails);
  }
  return (
    <div className="donation-container">
      <h1>Donate Now!</h1>
      <form className="donation-form" onSubmit={handleFormSubmit}>
        <Input
          value={firstName}
          type={"text"}
          placeholder={"First Name"}
          onChange={handleFormInputChange}
          name={"firstName"}
        />
        <Input
          value={lastName}
          type={"text"}
          placeholder={"Last Name"}
          onChange={handleFormInputChange}
          name={"lastName"}
        />
        <Input
          value={email}
          type={"text"}
          placeholder={"Email"}
          onChange={handleFormInputChange}
          name={"email"}
        />
        <Input
          value={phone}
          type={"text"}
          placeholder={"Phone"}
          onChange={handleFormInputChange}
          name={"phone"}
        />
        <Input
          value={amount}
          type={"text"}
          placeholder={"Amount"}
          onChange={handleFormInputChange}
          name={"amount"}
        />
        <br />
        <CountryDropdown
          value={country}
          className="country"
          onChange={(val) => setPayDetails({ ...payDetails, country: val })}
          required
        />
        <br />
        <br />
        <select
          name="currency"
          id="currency"
          required
          onChange={handleFormInputChange}
        >
          <option>Select currency</option>
          {variousCountry.map((data, idx) => (
            <option key={idx} value={data.val}>
              {data.title}
            </option>
          ))}
        </select>
        <br />
        <br/>
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Hold on..." : "Donate Now"}
        </button>
      </form>
    </div>
  );
};

export default DonationForm;
